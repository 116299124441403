import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container, Modal } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./OffplanDetailIcons.scss"
import FormFields from "../../../static/forms/offplan_floorplan.json"
import DefaultForm from "../forms/default-form-layout"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const OffplanDetailIcons = ({ iconData,isPreviewEnabled, offplanData }) => {
  const { isLargeScreen } = useDeviceMedia()
  const [show, setShow] = useState(false)
  const [floorPlanUrl, setFloorplanUrl] = useState('')

  const handleModal = data => {
    setShow(true)
    setFloorplanUrl(data)
  }

  const handleClose = () => {
    setShow(false)
  }


  let combinedData = iconData?.icon_list?.map((item, index) => {
    return { ...item, newheading: item?.heading }
  })
  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <>
      {(combinedData?.length>0||iconData)&&
    <div className="offplan-details-iconwrapper">
      <Container>
        <div className="offplan-icon-module">
          <div
            className="content-section_offplan"
            // animateIn="animate__slideInUp"
            // animateOnce
            // delay={100}
            // offset={100}
          >
            {/* <div className="content-section_offplan"> */}
            <h2 className="heading">{iconData?.title}</h2>
            {(iconData?.content||(isPreviewEnabled&&iconData?.content))&&
            <p className="description">
              <ContentModule Content={iconData?.content||iconData?.content} />
            </p>
            }
          </div>
          {combinedData?.length > 0 && (
            <div className="icon-section_offplan">
              {combinedData?.map((item, index) => {
                let iconName =
                  item.newheading === "Completion Date"
                    ? "new-dev-date"
                    : item.newheading === "Prices from"
                    ? "new-dev-price"
                    : item.newheading === "Developer"
                    ? "new-dev-developer"
                    : item.newheading === "Location"
                    ? "new-dev-location"
                    : item.newheading === "Development Type"
                    ? "new-dev-devtype"
                    : item.newheading === "Floorplan"
                    ? "new-dev-floorplan"
                    : item.newheading === "Siteplan"
                    ? "siteplan"
                    : ""
                return (
                  <>
                    <ScrollAnimation
                      className="item_list-offplan"
                      animateIn="animate__slideInUp"
                      animateOnce
                      delay={index * 100}
                      offset={100}
                    >
                      {(item?.image?.url||item?.image?.data?.attributes?.url)?
                        <img src={item?.image?.url||item?.image?.data?.attributes?.url} alt={item?.heading}/>
                      :
                      <div className="icon-offplan-holder">
                        <i className={`icon ${iconName}`} />
                      </div>
                      }
                      <h4>{item.newheading}</h4>
                      {item?.ctas?.link_type === "onclick" ? (
                        <p className="click-icons">
                         {item?.heading === 'Floorplan' ?
                          <a href="javascript:void(0)"
                          onClick={()=>handleModal(item?.ctas?.custom_link)} 
                          >
                          {item?.ctas?.title}
                        </a>
                         :
                        <a
                          href={item?.ctas?.custom_link} 
                          target="_blank"
                          >
                          {item?.ctas?.title}
                        </a>
                          }
                        </p>
                      ) : (
                        (item?.sub_content||(isPreviewEnabled&&item?.sub_content))&&
                        <p className="cta_title">
                          <ContentModule
                            Content={item?.sub_content}
                            />
                        </p>
                      )}
                    </ScrollAnimation>
                    {index % 2 === 0 && isLargeScreen ? (
                      <ScrollAnimation
                        className="vertical-line"
                        animateIn="animate__slideInUp"
                        animateOnce
                        delay={100}
                        offset={100}
                      >
                        {/* <div className="vertical-line"></div> */}
                      </ScrollAnimation>
                    ) : (
                      (index + 1) % 4 !== 0 &&
                      !isLargeScreen && (
                        <ScrollAnimation
                          className="vertical-line"
                          animateIn="animate__slideInUp"
                          animateOnce
                          delay={100}
                          offset={100}
                        >
                          {/* <div className="vertical-line"></div> */}
                        </ScrollAnimation>
                      )
                    )}
                  </>
                )
              })}
            </div>
          )}
        </div>
      </Container>
      <Modal
        show={show}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form news_subscribe offplan-brochure"
      >
        <Modal.Body>
          <div className="popup-form-wrapper news_subscribe">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm
                sourceUrl={pageurl}
                phone_id_value={"floorplan_download-button"}
                fields={FormFields}
                formTitle='Download Floorplan'
                prop_address={offplanData?.banner_title}
                brochureUrl={floorPlanUrl}
                to_email_id={
                  offplanData?.additional_email
                    ? offplanData?.additional_email +
                      ", hauswebsiteleads@gmail.com"
                    : "hauswebsiteleads@gmail.com"
                }
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
      }
    </>
  )
}

export default OffplanDetailIcons
