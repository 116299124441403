import React, {useState,useEffect } from "react"
import { Container, Modal } from "react-bootstrap"
import "./ContentComponent.scss"
import FormFields from "../../../static/forms/leave_feedback.json"
import DefaultForm from "../forms/default-form-module"
import ScrollAnimation from "react-animate-on-scroll"


const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const ContentComponent = ({ data,layout,isPreviewEnabled, isOffplanLanding}) => {

  const [show, setShow] = useState(false)

  const handleModal = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }
  useEffect(()=>{
    setTimeout(()=>{
      const getId=document.getElementById("leave-feedback");
      getId?.addEventListener("click", handleModal)
    },2000)
    
  },[])

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

 
  return (
    <div className={`landing-content-wrapper ${layout?layout:""} ${isOffplanLanding?"isOffplanLanding-cont":""}`}>
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container>
        <div className="content-section">
          <h1 className="heading">{data?.title}</h1>
          {(data?.description?.data?.description || (data?.description&&isPreviewEnabled)) &&
            <p className="description">
              <ContentModule Content={data?.description?.data?.description || data?.description } />
            </p>
          }
        </div>
      </Container>
      </ScrollAnimation>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form contact-form"
      >
        <Modal.Body>
          <div className="popup-form-wrapper contact-form">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm 
                fields={FormFields} 
                formTitle={"Leave Feedback"}
                sourceUrl={pageurl}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ContentComponent
