import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import { Row, Col } from "react-bootstrap"
import loadable from "@loadable/component"
import "./Pagination.scss"
import { customStylesNews } from "../Common/utils"
const Select = loadable(() => import("react-select"))

const Pagination = ({ pageSize, totalCount, currentPage }) => {
  const totalPages = Math.ceil(totalCount / pageSize)
  const [selectedPage, setSelectedPage] = useState({
    label: currentPage,
    value: currentPage,
  })
  const prevPage = currentPage - 1
  const nextPage = currentPage + 1
  const hasNextPage = nextPage <= totalPages
  const hasPrevPage = prevPage >= 1

  const pageNumberOptions = Array.from({ length: pageSize }).map((_, i) => {
    return { label: i + 1, value: i + 1 }
  })

  const handlePageChange = item => {
     setSelectedPage(item)
    if (item.value !== currentPage) {
      if (item.value === 1) {
        navigate(`/guides/area-guides-dubai/`)
      } else {
        navigate(`/guides/area-guides-dubai/page-${item.value}`)
      }
    }
  }
  const prevLink =
    prevPage === 1
      ? "/guides/area-guides-dubai/"
      : `/guides/area-guides-dubai/page-${prevPage}`
  const nextLink = `/guides/area-guides-dubai/page-${nextPage}`

  return (
    <>
      <div className="inner-pagination-wrapper new-pagination">
        <Row>
          <Col>
            <div className="load-more-inner-wrap">
              <div className="d-flex align-items-center justify-content-between">
                <Link
                  className={`button button-outline results-btn d-flex align-items-center
                    ${!hasPrevPage ? "noprepage" : ""}`}
                  to={prevLink}
                >
                  <i className="icon icon-results-back "></i>
                  <span>Back</span>
                </Link>

                <div className="text-center pages-count d-flex align-items-center">
                  <div className="spacing spacing-right">Page</div>
                  <Select
                    options={pageNumberOptions}
                    isSearchable={false}
                    placeholder={"1"}
                    className={"select-control"}
                    classNamePrefix={"react-select"}
                    styles={customStylesNews}
                    value={selectedPage}
                    onChange={handlePageChange}
                    components={{
                      DropdownIndicator: () => (
                        <i className="icon icon-select-dropdown-dark"></i>
                      ),
                      IndicatorSeparator: () => null,
                    }}
                  />
                  <div className="spacing spacing-both">of</div>
                  <div className="spacing">{pageSize}</div>
                </div>
                <Link
                  className={`button button-outline results-btn d-flex align-items-center
                    ${!hasNextPage ? "noNextpage" : ""}`}
                  to={nextLink}
                >
                  <span>Next</span> <i className="icon icon-results-next"></i>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Pagination
