import React, { useState } from "react"
import { Container } from "react-bootstrap"
import CustomSlider from "../CustomSlider/CustomSlider"
import PlayVideo from "../PlayVideo/PlayVideo"
import "./OfficeTourComponent.scss"
import GGFXImage from "../../modules/GGFXImage"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import getVideoId from "get-video-id"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const OfficeTourComponent = ({ data, pageData, pageName }) => {
  const [isPlay, setPlay] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")

  const { isMobile } = useDeviceMedia()

  const handleVideo = item => {
    setPlay(true)
    setVideoUrl(item?.video_url)
  }

  const imageName = "page.primary_module_image.tile_image"
  return (
    <div className={`office-tour-wrapper ${pageName}`}>
      <Container>
        <div className="office-tour-module">
          <h2>{data?.title}</h2>
          <div className="description">
             <ContentModule
              Content={data?.content?.data?.content}
            />
          </div>

          <CustomSlider
            // dots={true}
            className="office-tour-slider"
            slidecount={isMobile ? 1 : 3}
            showArrows
          >
            {data?.items?.length > 0 &&
              data?.items?.map((item, index) => {
                const videoid = item?.video_url && getVideoId(item?.video_url)
                let youTubeImage=""
                if(videoid?.id){
                  youTubeImage= `https://i.ytimg.com/vi/${videoid?.id}/maxresdefault.jpg`
                }
                return (
                  <div className="office-tour-item">
                    <div className="image-section">
                      {youTubeImage ? 
                    <img className="thumbnail" src={youTubeImage} alt=""/>:
                      <GGFXImage
                        ImageSrc={item?.image}
                        altText={`${
                          item?.image?.alternativeText
                            ? item?.image?.alternativeText
                            : item.title
                        }`}
                        imagetransforms={pageData?.ggfx_results}
                        renderer="srcSet"
                        imagename={imageName}
                        strapiID={pageData?.strapi_id}
                      />
                    }
                      {item?.video_url && (
                        <button
                          onClick={() => handleVideo(item)}
                          className="play-button"
                        >
                          <i className="icon video-filled-icon" />
                        </button>
                      )}
                    </div>

                    <div className="office-tour-name">
                      <h3>{item?.title}</h3>
                    </div>
                  </div>
                )
              })}
          </CustomSlider>
        </div>
      </Container>
      <PlayVideo
        isOpen={isPlay}
        stopPlay={setPlay}
        videoId=""
        isCloseFunction={setPlay}
        videourl={videoUrl}
        htmlink={""}
      />
    </div>
  )
}

export default OfficeTourComponent
